import React, { useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import { debounce } from "lodash";

const CustomNoRowsOverlay = ({ data, isLoading }) => {
  if (isLoading) {
    return null; // Don't display anything while loading
  }

  if (data?.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Typography variant="subtitle1">No records found</Typography>
      </Box>
    );
  }

  return null; // Return nothing if there's data
};

const DataGridTable = ({
  data,
  columns,
  onRowClick,
  rowsPerPageOptions,
  pageSize,
  pageSizeRef,
  params,
  setParams,
  rowCount,
  onPageChange,
  isLoading,
  page,
  getRowId = false,
  isHideTableFooter = false,
  onCellClick,
  ...rest
}) => {

  const pageChangeHandle = (page) => {
    setParams({
      ...params,
      offset: page + 1
    })
  }

  if (getRowId) {
    rest.getRowId = getRowId
  }

  const handlePageSizeChange = (newPageSize) => {
    setParams({
      ...params,
      limit: newPageSize || 10,
    });
    pageSizeRef.current = newPageSize
  };

  const handleFilterModelChange = (newFilterModel) => {
    const allowedEmptyValueOperator = ["isEmpty", "isNotEmpty"]

    const hasEmptyValues = newFilterModel.items.some(
      (filter) => (allowedEmptyValueOperator.includes(filter.operatorValue) ? false : (filter.value === null || filter.value === ""))
    );

    if (newFilterModel?.items?.length <= 0 || hasEmptyValues) {
      setParams({
        ...params,
        columnFilter: {},
      });
    }

    const filterItem = newFilterModel?.items?.[0]

    // manage debounce to for state update
    if (filterItem && (filterItem?.value || filterItem?.value === 0 || allowedEmptyValueOperator.includes(filterItem.operatorValue))) {
      setParams({
        ...params,
        columnFilter: {
          columnField: filterItem?.columnField,
          operatorValue: filterItem?.operatorValue,
          value: filterItem?.value || "",
        },
      });
    }
  }

  const debounced = useCallback(debounce(handleFilterModelChange, 500), []);

  const handleSortModelChange = (sortingData) => {
    if (sortingData?.[0]) {
      setParams({
        ...params,
        sort: sortingData[0],
      });
    }
  }

  return (
    // data?.length > 0 && (
    <DataGrid
      getRowId={(row) => getRowId ? row?.uniqueId : row?.id}
      autoHeight
      // autoPageSize
      hideFooter={isHideTableFooter}
      // getRowId={(row) => getRowId ? row[getRowId] : (row?.id || row?._id )}
      // onCellClick={(params, event) => {
      //   event.defaultMuiPrevented = true;
      // }}
      onCellClick={onCellClick}
      onRowClick={(row, event) => onRowClick(row?.row, event)}
      rows={data || []}
      columns={columns}
      disableSelectionOnClick
      style={{ background: "#ffffff", border: "none" }}
      paginationMode={"server"}
      filterMode="server" // Disable client-side filtering
      onFilterModelChange={(newFilterModel, details) => debounced(newFilterModel, details)} // Handle filtering manually
      sortingMode="server" // Disable client-side sorting
      onSortModelChange={handleSortModelChange}  // Handle sorting manually
      rowsPerPageOptions={rowsPerPageOptions || [10, 20, 50, 100]}
      pageSize={pageSize || 10}
      onPageSizeChange={handlePageSizeChange}
      rowCount={rowCount}
      onPageChange={(page) => pageChangeHandle(page)}
      loading={isLoading}
      page={page - 1 || 0}
      components={{
        NoRowsOverlay: () => <CustomNoRowsOverlay data={data} isLoading={isLoading} />,
      }}
      sx={{
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
          width: "7px",
          height: "7px",
        },
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
          backgroundColor: "#999",
        },
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
          background: "#616161",
        },
      }}
      {...rest}
    />
  )
  // );
};

export default DataGridTable;