import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, TextField, Typography, Autocomplete, Button, Stack, FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import * as Yup from "yup";
import { debounce } from "lodash";
import PhoneNumber from "./PhoneNumber";
import GoogleAddressSearch from "../Utils/GoogleAddressSearch";
import regex from "../../utils/regex";
import {
  userDetailsSelector,
  saveEmployer,
  createEmployer,
  addAddress,
  // deleteAddress,
  updateAddress,
  deleteEmployer,
  setRequiredFieldsErrors,
} from "../../store/slices/applicationFormSlice";

// import { getBusinessByABN, getBusinessByName } from "../../services/abr";

import RadioButtonGroup from "../Utils/RadioButtonGroup";
import AutoCompleteLoader from "../Application/AutoCompleteLoader";
import { occupationListOptions } from "../../utils/OccupationList";
import { employerIndustryListOptions } from "../../utils/employerIndustryList";
import { employerWorkStatus } from "../../constants";
import { userPermissionsSelector } from "../../store/slices/userSlice";
import { customerSelector } from "../../store/slices/customerSlice";
const Employer = React.memo(({ customer, employer, index, customerId, applicationId, customerIndex, setFieldErrors }) => {
  const dispatch = useDispatch();
  const permissions = useSelector(userPermissionsSelector);
  const { isCustomerLogin } = useSelector(customerSelector);
  const isDisable = isCustomerLogin ? true : permissions?.application?.edit;
  // const { gettingEntityDetails } = useSelector(userDetailsSelector);

  const { requireFieldErrors } = useSelector(userDetailsSelector);
  // const { consumerFieldErrors } = lenderRequiredFieldsError;

  // const [fieldErrors, setFieldErrors] = useState({
  //   occupation: "",
  //   industry: "",
  //   status: "",
  //   managerValue: "",
  //   // Number: "",
  //   phoneNumber: "",
  // });

  // const { gettingEntityDetails, removeEmployerLoader } = useSelector(userDetailsSelector);

  // const [loadingBusinessSearch, setLoadingBusinessSearch] = useState("");
  // const [entityOptions, setEntityOptions] = useState([]);

  // const [searchValue, setSearchValue] = useState({
  //   businessSearchValue: "",
  //   businessSearchInputText: "",
  // });

  // useEffect(() => {
  //   if (consumerFieldErrors?.employers) {
  //     setFieldErrors({
  //       occupationValue: consumerFieldErrors?.employers?.occupation,
  //       industryValue: consumerFieldErrors?.employers?.industry,
  //       status: consumerFieldErrors?.employers?.status,
  //       managerValue: consumerFieldErrors?.employers?.manager,
  //       Number: consumerFieldErrors?.employers?.phoneNumber,
  //     })
  //   }
  // }, [consumerFieldErrors?.employers])

  const [allValues, setAllValue] = useState({
    entityName: employer?.entityName,
    employmentTypeValue: employer?.employmentType,
    timeOfEmployment: employer.timeOfEmployment,
    occupation: employer?.occupation,
    manager: employer?.manager,
    phoneNumberValue: employer.phoneNumber,
    industry: employer.industry,
    status: employer.status,
  });

  useEffect(() => {
    if (employer) {
      setAllValue({
        entityName: employer?.entityName,
        employmentTypeValue: employer?.employmentType,
        timeOfEmployment: employer.timeOfEmployment,
        occupation: employer?.occupation,
        manager: employer?.manager,
        phoneNumberValue: employer.phoneNumber,
        industry: employer.industry,
        status: employer.status,
      });
    }
  }, [employer]);

  const validationSchema = Yup.object({
    timeOfEmployment: Yup.string()
      .optional()
      .matches(regex.timeOfEmploymentRegex, {
        message: "Employment length must be up to 1200 months",
      })
      .max(4, "Maximum of 4 digits"),
    occupation: Yup.string()
      .required("Occupation is required")
      .matches(regex.occupationRegex, "Only letters and ' - characters allowed.")
      .min(2, "Maximum of 2 characters")
      .max(100, "Maximum of 100 characters"),
    industry: Yup.string()
      .required("Industry is required")
      .min(2, "Maximum of 2 characters")
      .max(50, "Maximum of 50 characters"),
    status: Yup.string()
      .required("Status is required")
      .min(2, "Maximum of 2 characters")
      .max(50, "Maximum of 50 characters"),
    manager: Yup.string()
      .required("Manager name is required")
      .matches(regex.nameRegex, "Only letters and ' - characters allowed.")
      .min(2, "Maximum of 2 characters")
      .max(50, "Maximum of 50 characters"),
    phoneNumberValue: Yup.string()
      .required("number is required")
      .matches(regex.phoneNumber, "Enter a valid Australian landline number."),
    fullAddress: Yup.string()
      .required("address field is required")
      // .matches(regex.addressRegex, "Please enter valid address")
      .min(3, "Maximum of 3 characters")
      .max(200, "Maximum of 200 characters"),
  });

  const debouncedValidation = useCallback(
    debounce(async (fieldName, value) => {
      const name = fieldName.split("_")[0];
      try {
        await validationSchema.validateAt(name, { [name]: value });
        setFieldErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        dispatch(setRequiredFieldsErrors({
          ...requireFieldErrors,
          [fieldName]: ""
        }))
      } catch (error) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [name]: error.message,
        }));
        dispatch(setRequiredFieldsErrors({
          ...requireFieldErrors,
          [fieldName]: error.message
        }))
      }
    }, 300),
    []
  );

  const handle = {
    onChangeField: async (e, fieldName) => {
      let isValid = true;
      const name = fieldName.split("_")[0];
      if (name === "occupationValue") {
        isValid = occupationListOptions.includes(e)
      } else if (name === "managerValue") {
        isValid = regex.nameRegex.test(e) && e?.length <= 50;
      } else if (name === "phoneNumberValue") {
        // isValid = e === "" || regex.addressRegex.test(e);
        isValid = e === "" || regex.phoneNumber.test(e);
      }

      if (!isValid) {
        // await validateField(fieldName, e);
        debouncedValidation(fieldName, e);
        return;
      } else {
        setAllValue({ ...allValues, [name]: e });
        debouncedValidation(fieldName, e);
      }
    },
    onChangeRadio: (value, name) => {
      setAllValue({ ...allValues, [name]: value });
    },
    commonEventHandle: (value, name) => {
      dispatch(
        saveEmployer({
          isCustomerLogin,
          data: {
            [name]: value,
            employerId: employer._id,
            customer: customer._id,
          },
        }),
      );
    },
    addAddress: (address) => {
      console.log("update address", address);
      if (address) {
        dispatch(
          createEmployer({
            isCustomerLogin,
            data: {
              employerId: employer._id,
              customer: customer._id,
              address: address,
              applicationId,
              isManualAddress: false,
              customerId: customer._id,
              isEmployerAddress: true
            },
          }),
        );
      }
    },
    deleteEmployer: ({ customerId, employerId, applicationId }) => {
      dispatch(deleteEmployer({ isCustomerLogin, data: { customerId, employerId, applicationId } }));
    },
  };

  const handleAddAddress = (newAddressValue) => {
    if (newAddressValue) {
      dispatch(
        addAddress({
          isCustomerLogin,
          data: {
            employerId: employer._id,
            address: newAddressValue,
            applicationId,
            customerId: customer._id,
            isManualAddress: false,
            isEmployerAddress: true
          },
        }),
      );
    }
  };

  const handleDeleteAddress = (newAddressValue) => {
    if (newAddressValue) {
      // if (REACT_APP_PLATFORM_NAME === PLATFORM_NAME.CONNECTIVE) {
      // For connective we just have update employer address only
      handleUpdateAddress({
        _id: newAddressValue?._id,
        address: null
      })
      return
      // } else {
      // dispatch(
      //   deleteAddress({
      //     isCustomerLogin,
      //     data: {
      //       employerId: employer._id,
      //       addressId: newAddressValue?._id,
      //       customerId: customer._id,
      //       applicationId,
      //       isEmployerAddress: true
      //     }
      //   }),
      // );
      // }
    }
  };

  const handleUpdateAddress = (newAddressValue) => {
    if (newAddressValue) {
      dispatch(updateAddress({
        isCustomerLogin,
        data: {
          ...newAddressValue,
          applicationId,
          customerId: customer._id,
          employerId: employer._id,
          isEmployerAddress: true
        }
      }));
    }
  };

  // const [ABREntityDetails, setABREntityDetails] = useState({});
  // const [newBusinessValue, setNewBusinessValue] = useState("");

  // const parseTimeInBusiness = moment(entity?.timeInBusiness, "DD-MM-YYYY");

  // const handleEntity = (update) => {
  //   // console.log("update phoneNumber");
  //   dispatch(updateEntity(update));
  // };

  // const debounced = useMemo(() =>
  //   debounce(async (inputValue) => {
  //     // console.log("inputValue", inputValue);
  //     if (inputValue) {
  //       setLoadingBusinessSearch(true);
  //       if (isDigitsOnly.test(inputValue)) {
  //         const businessByABN = await getBusinessByABN(inputValue);
  //         setLoadingBusinessSearch(false);
  //         if (businessByABN.Abn.length > 1) {
  //           return setEntityOptions([businessByABN]);
  //         }
  //       }

  //       const businesses = await getBusinessByName(inputValue);
  //       if (businesses?.Names?.length > 0) {
  //         setLoadingBusinessSearch(false);
  //         return setEntityOptions(
  //           businesses.Names.map(
  //             (i) =>
  //               // return { label: `${i.Name} ${i.Abn}`, Abn: i.Abn, value: i };
  //               i,
  //           ),
  //         );
  //       }

  //       setEntityOptions([
  //         {
  //           Name: "No results found",
  //         },
  //       ]);
  //     }
  //   }, 500),
  // );

  // const handleEntityDetails = async (details) => {
  //   dispatch(saveValue({ gettingEntityDetails: true }));
  //   console.log("details", details);
  //   const abrData = await getBusinessByABN(details.Abn);
  //   console.log("ABREntityDetails", abrData);
  //   setABREntityDetails(abrData);
  //   console.log(abrData.EntityName !== employer?.entityName);
  //   console.log(abrData.EntityName, employer?.entityName);

  //   if (abrData.EntityName !== employer?.entityName) {
  //     // await dispatch(getCompanyProfile({ ACN: abrData.Acn })).unwrap();
  //     dispatch(createEmployer({ abrData, customerId: customer._id }));
  //   } else {
  //     dispatch(saveValue({ gettingEntityDetails: false }));
  //   }
  // };

  // useEffect(() => {
  //   debounced(searchValue?.businessSearchInputText);
  //   return () => debounced.cancel();
  // }, [searchValue?.businessSearchValue, searchValue?.businessSearchInputText]);

  // useEffect(() => {
  //   if (!gettingEntityDetails) {
  //     setSearchValue({
  //       businessSearchValue: "",
  //       businessSearchInputText: "",
  //     });
  //   }
  // }, [gettingEntityDetails]);

  return (
    <>
      <Grid
        style={{
          margin: "0 0 30px 0",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          paddingBottom: "30px",
        }}
      >
        <Stack direction="row" alignItems="start" justifyContent="space-between" style={{ margin: "0 0 20px" }}>
          {employer?.entityName ? (
            <Typography style={{ marginBottom: "20px" }}>
              {`Employer ${index + 1}: ${employer?.entityName}`}
            </Typography>
          ) : (
            <Grid item md={7} sm={6}>
              <Grid item md={12} sm={12} style={{ margin: "0 0 20px" }}>
                <Typography fontWeight={600}>Employer details</Typography>
              </Grid>
              <TextField
                fullWidth
                // id="outlined-basic"
                disabled={!isDisable}
                type="text"
                name={`entityName_${customer._id}_${index}`}
                label={`Employer ${index + 1} name`}
                variant="filled"
                size="small"
                value={allValues?.entityName}
                style={{ marginBottom: "20px" }}
                onChange={(event) => handle.onChangeField(event?.target?.value, `entityName_${customer._id}_${index}`)}
                onBlur={() =>
                  allValues?.entityName ? handle.commonEventHandle(allValues?.entityName, "entityName") : null
                }
              />
            </Grid>
          )}
          <Button
            variant="outlined"
            size="small"
            // disabled={removeEmployerLoader}
            onClick={() => {
              handle.deleteEmployer({ customerId: customer._id, employerId: employer._id, applicationId });
            }}
          >
            {/* {removeEmployerLoader ? 'Removing...' : 'Remove employer'} */}
            Remove employer
          </Button>
        </Stack>
        <Grid container spacing={1}>
          <Grid container item spacing={2} alignItems="end">
            <Grid item md={9} sm={9}>
              <Typography variant="subtitle2">Employment type</Typography>
              <RadioButtonGroup
                disabled={!isDisable}
                value={allValues?.employmentTypeValue}
                handleValue={(value) => {
                  handle.onChangeRadio(value, "employmentTypeValue");
                  handle.commonEventHandle(value, "employmentType");
                }}
                optionStyle={{
                  width: "80%",
                  padding: "8px",
                  minHeight: "48px",
                }}
                name={`employmentType_${customer._id}_${index}`}
                options={[
                  { icon: "House", label: "Full-time" },
                  { icon: "HomeWork", label: "Part-time" },
                  { icon: "Hotel", label: "Casual" },
                  { icon: "Hotel", label: "Contract" },
                  { icon: "House", label: "Self-employed" },
                  { icon: "Hotel", label: "Benefits" },
                  { icon: "Hotel", label: "Unemployed" },
                ]}
              />
            </Grid>

            <Grid item md={3} sm={3}>
              {/* <Typography variant="subtitle2">
                Length of employment {allValues?.timeOfEmployment || 0} months
              </Typography>
              <Box style={{ padding: "0 14px 0 7px" }}>
                <Slider
                  color="secondary"
                  size="large"
                  disabled={!isDisable}
                  marks={[
                    { value: 0, label: 0 },
                    // { value: 6, label: 6 },
                    // { value: 15, label: 15 },
                    { value: 12, label: 12 },
                    // { value: 25, label: 25 },         
                    // { value: 18, label: 18 },
                    // { value: 35, label: 35 },
                    { value: 24, label: 24 },
                    { value: 48, label: 48 },
                    { value: 72, label: 72 },
                    { value: 96, label: 96 },
                    { value: 120, label: "+120" },
                  ]}
                  name={`timeOfEmployment_${customer._id}_${index}`}
                  step={1}
                  min={0}
                  max={120}
                  value={allValues?.timeOfEmployment}
                  onChange={(event) => handle.onChangeField(event?.target?.value, `timeOfEmployment_${customer._id}_${index}`)}
                  onChangeCommitted={() =>
                    handle.commonEventHandle(allValues?.timeOfEmployment, "timeOfEmployment")
                  }
                  error={requireFieldErrors[`timeOfEmployment_${customer._id}_${index}`]}
                  helperText={requireFieldErrors[`timeOfEmployment_${customer._id}_${index}`]}
                  aria-label="Default"
                  valueLabelDisplay="auto"
                />
              </Box> */}
              <Typography variant="caption">
                Time of employment (in months)
              </Typography>
              <TextField
                fullWidth
                id="timeOfEmployment"
                label="Time of employment"
                variant="filled"
                type="text"
                size="small"
                name={`timeOfEmployment_${customer._id}_${index}`}
                value={allValues?.timeOfEmployment}
                error={requireFieldErrors[`timeOfEmployment_${customer._id}_${index}`]}
                helperText={requireFieldErrors[`timeOfEmployment_${customer._id}_${index}`]}
                onChange={(event) => handle.onChangeField(event?.target?.value, `timeOfEmployment_${customer._id}_${index}`)}
                onBlur={(event) => +event?.target?.value > 0 && +event?.target?.value <= 1200 && handle.commonEventHandle(+event?.target?.value, "timeOfEmployment")}
              />
            </Grid>
          </Grid>
          <Grid container md={12} sm={12} xl={12} item spacing={1}>
            <Grid item md={3} sm={6} xs={6}>
              <Autocomplete
                autoComplete
                loadingText={<AutoCompleteLoader />}
                value={allValues?.occupation}
                options={occupationListOptions}
                disabled={!isDisable}
                onChange={(event, newValue) => {
                  handle.onChangeField(newValue || "", `occupation_${customer._id}_${index}`);
                }}
                onBlur={() =>
                  allValues?.occupation ? handle.commonEventHandle(allValues?.occupation, "occupation") : null
                }
                name={`occupation_${customer._id}_${index}`}
                size="small"
                getOptionLabel={(option) => option || ""}
                renderInput={(params) => (
                  <TextField
                    error={requireFieldErrors[`occupation_${customer._id}_${index}`]}
                    helperText={requireFieldErrors[`occupation_${customer._id}_${index}`]}
                    size="small"
                    {...params}
                    label="Occupation"
                    variant="filled"
                  />
                )}
              />
            </Grid>

            <Grid item md={3} sm={6} xs={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                type="text"
                name={`manager_${customer._id}_${index}`}
                label="Manager"
                variant="filled"
                size="small"
                disabled={!isDisable}
                value={allValues?.manager}
                error={requireFieldErrors[`manager_${customer._id}_${index}`]}
                helperText={requireFieldErrors[`manager_${customer._id}_${index}`]}
                onChange={(event) => handle.onChangeField(event?.target?.value, `manager_${customer._id}_${index}`)}
                onBlur={() =>
                  allValues?.manager ? handle.commonEventHandle(allValues?.manager, "manager") : null
                }
              />
            </Grid>

            <Grid item md={3} sm={6} xs={6}>
              <PhoneNumber
                label={"Contact number"}
                fieldKey="phoneNumberValue"
                disabled={!isDisable}
                guarantorFieldErrors={requireFieldErrors}
                // setGuarantorFieldErrors={setFieldErrors}
                saveContactNum={(key, value) => handle.commonEventHandle(value, key)}
                phoneNumber={allValues?.phoneNumberValue}
                applicationId={applicationId}
                customerId={customerId}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={6}>
              <FormControl fullWidth variant="filled">
                <InputLabel
                  id="work-status"
                  // error={fieldErrors?.status}
                  error={requireFieldErrors[`status_${customer._id}_${index}`]}
                >
                  Status
                </InputLabel>
                <Select
                  size="small"
                  labelId="work-status"
                  id="work-status"
                  value={allValues?.status}
                  label="Status"
                  name={`status_${customer._id}_${index}`}
                  onChange={(event) => handle.onChangeField(event?.target?.value, `status_${customer._id}_${index}`)}
                  onBlur={() => (allValues?.status ? handle.commonEventHandle(allValues?.status, "status") : null)}
                  error={requireFieldErrors[`status_${customer._id}_${index}`]}
                >
                  {employerWorkStatus.map((i) => (
                    <MenuItem value={i.value}>{i.label}</MenuItem>
                  ))}
                </Select>
                {requireFieldErrors[`status_${customer._id}_${index}`] && (
                  <FormHelperText sx={{ color: "#d32f2f" }}>{requireFieldErrors[`status_${customer._id}_${index}`]}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container item sm={12} spacing={1}>
            <Grid item md={4} sm={4}>
              <Autocomplete
                autoComplete
                loadingText={<AutoCompleteLoader />}
                name={`industry_${customer._id}_${index}`}
                disabled={!isDisable}
                value={allValues?.industry}
                options={employerIndustryListOptions}
                onChange={(event, newValue) => {
                  handle.onChangeField(newValue || "", `industry_${customer._id}_${index}`);
                }}
                onBlur={() =>
                  allValues?.industry ? handle.commonEventHandle(allValues?.industry, "industry") : null
                }
                size="small"
                getOptionLabel={(option) => option || ""}
                renderInput={(params) => (
                  <TextField
                    error={requireFieldErrors[`industry_${customer._id}_${index}`]}
                    helperText={requireFieldErrors[`industry_${customer._id}_${index}`]}
                    size="small"
                    {...params}
                    label="Industry"
                    variant="filled"
                  />
                )}
              />
            </Grid>
            <Grid item md={8} sm={8}>
              <GoogleAddressSearch
                customerId={customer?._id}
                disabled={!isDisable}
                singleCustomerId={customerId}
                applicationId={applicationId}
                guarantorFieldErrors={requireFieldErrors}
                setGuarantorFieldErrors={setFieldErrors}
                handleCreateAddress={handleAddAddress}
                handleUpdateAddress={handleUpdateAddress}
                handleRemoveAddress={handleDeleteAddress}
                address={employer?.address}
                index={index}
                isEmptyAddress={!employer?.address?.street}
                addressFieldName={`fullAddress_employer_${customer?._id}_${index}`}
                validateComponentField={debouncedValidation} // validateField
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});

export default Employer;
