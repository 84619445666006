import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { reusableFetch } from "../../utils/useAxios";

export const getCalculatorList = createAsyncThunk(
  "calculator/getCalculatorList",
  async (data, { rejectWithValue }) => {
    const calculatorApplications = await reusableFetch(`application/all`, "POST", data, rejectWithValue);
    return calculatorApplications;
  },
);

const defaultState = {
  openAmortisationModal: false,
  isLoading: false,
  calculators: {},
  filterDate: {
    startDate: null,
    endDate: null
  }
}

export const calculatorSlice = createSlice({
  name: "calculator",
  initialState: defaultState,
  reducers: {
    saveCalculatorValue: (state, action) => _.merge(state, action.payload),
    setCalculatorFilterDate: (state, action) => {
      state.filterDate = action.payload
    },
  },
  extraReducers: {
    [getCalculatorList.pending]: (state) => {
      state.isLoading = true
      state.isFetching = true;
    },
    [getCalculatorList.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.calculators = payload.data?.data
    }, 
    [getCalculatorList.rejected]: (state, { payload }) => {
      state.isLoading = false
    },
  },
});

export const { saveCalculatorValue, setCalculatorFilterDate } = calculatorSlice.actions;

export const calculatorSelector = (state) => state.calculator;
export const calculatorsListSelector = (state) => state.calculator.calculators;
