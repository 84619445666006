import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, Grid, MenuItem, Select, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment/moment";
import _ from "lodash";
import { useSnackbar } from "notistack";
// import Switch from '@mui/joy/Switch';
import { userSelector, updateUserRole, getOrganisationUser } from "../../../store/slices/userSlice";
// import { usersSelector } from "src/store/slices/usersSlice";
import { ERRORS_MESSAGE, STATUS_CODE, userType, userTypeOptions } from "../../../constants";
import DataGridTable from "../../../components/dataGridTable/DataGridTable";

const useStyles = makeStyles(() => ({
  borderRemove: {
    "& fieldset": {
      border: "0px !important",
    },
  },
}));

const UserRolesList = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const pageSizeRef = useRef(10);
  const user = useSelector(userSelector);
  const users = user?.oraganisationUser;
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [params, setParams] = useState({ offset: 1 });
  const [rowCountState, setRowCountState] = useState(users?.totalDocs || 0);

  const masterColumns = [
    {
      field: "date",
      headerName: "Date",
      width: 100,
      editable: false,
    },
    {
      field: "firstName",
      headerName: "First name",
      width: 150,
      editable: false,
    },
    {
      field: "lastName",
      headerName: "Last name",
      width: 150,
      editable: false,
    },
    {
      field: "phoneNumber",
      headerName: "Phone number",
      width: 130,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 180,
      editable: false,
    },
    {
      field: "roles",
      headerName: "User Roles",
      width: 150,
      // editable: true,
      renderCell: ({ row }) => (
        <>
          {[userType.owner, userType.master].includes(row?.role) ? (
            <Typography sx={{ textAlign: "center", margin: 2 }}>{_.startCase(row?.role)}</Typography>
          ) : (
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={row?.role || ""}
                disabled={row.role === userType.owner}
                className={classes.borderRemove}
                onChange={(event) => handleUserUpdate(row?.id, event.target.value)}
              >
                {userTypeOptions
                  .filter((el) => el !== userType.master)
                  .map((item) => (
                    <MenuItem value={item} key={item} disabled={item === userType.owner}>
                      {_.startCase(item)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </>
      ),
    },
  ];

  function handleGetAllUsers(cursor) {
    dispatch(getOrganisationUser({ cursor, limit: pageSizeRef?.current || 10, }));
  }

  useEffect(() => {
    if (user) {
      handleGetAllUsers(users?.page || 1);
    }
  }, []);

  useEffect(() => {
    setRowCountState((prevRowCountState) => users?.totalDocs ?? prevRowCountState);
  }, [users?.totalDocs]);

  // const queryOptions = React.useMemo(
  //   () => ({
  //     cursor: mapPageToNextCursor.current[page - 1],
  //     pageSize: 10,
  //   }),
  //   [page],
  // );

  // const { isLoading, data, pageInfo } = useQuery(queryOptions);

  const handlePageChange = (newPage) => {
    // We have the cursor, we can allow the page transition.
    // if (newPage === 0 || applications.nextPage) {
    handleGetAllUsers(newPage + 1);
    setPage(newPage);
    // }
  };

  const handleUserUpdate = (id, value) => {
    const data = {
      userId: id,
      data: {
        userType: value,
      },
    };
    dispatch(updateUserRole(data)).then(({ payload }) => {
      if (payload?.status_code === STATUS_CODE.success) {
        enqueueSnackbar(payload?.status_message, {
          variant: "success",
          autoHideDuration: 5000,
        });
        const updatedRow = rows?.map((doc) => (doc?.id === id ? { ...doc, role: value } : doc));
        setRows(updatedRow);
      } else {
        enqueueSnackbar(payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    });
  };

  useEffect(() => {
    setRows(
      users?.docs?.map((doc) => {
        const date = new Date(doc.createdAt);
        return {
          id: doc?._id,
          firstName: doc?.firstName,
          lastName: doc?.lastName,
          phoneNumber: doc?.phoneNumber?.number,
          email: doc?.email?.address,
          userId: doc._id,
          role: doc?.userType,
          applicationsView: doc?.applicationsView,
          status: user?.status,
          date: `${moment(date).format("D/M")} ${moment(date).format("H:mm")}`,
        };
      }),
    );
  }, [users]);
  return (
    <Grid container spacing={1.5} direction={"column"}>
      <DataGridTable
        data={rows || []}
        columns={masterColumns}
        onRowClick={() => null}
        rowsPerPageOptions={[10, 20, 50, 100]}
        pageSize={pageSizeRef?.current || 10}
        pageSizeRef={pageSizeRef}
        rowCount={rowCountState}
        params={params}
        setParams={setParams}
        onPageChange={handlePageChange}
        page={page || 10}
        // disableSelectionOnClick
        style={{ background: "#ffffff", border: "none", width: "calc(100% + -114px)" }}
      // loading={isLoading}
      // minHeight: "60vh",

      // paginationMode="server"
      // sx={{
      //   "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
      //     width: "7px",
      //     height: "7px",
      //   },
      //   "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
      //     background: "#f1f1f1",
      //   },
      //   "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
      //     backgroundColor: "#999",
      //   },
      //   "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
      //     background: "#616161",
      //   },
      // }}
      />
    </Grid>
  );
};

export default UserRolesList;
