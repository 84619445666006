import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, FormControl, Grid, IconButton, Input, InputAdornment, Stack, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import clsx from "clsx";
import { debounce } from "lodash";
import { userSelector } from "../../store/slices/userSlice";
import { getAllOrganisation, organisationsSelector, saveValue } from "../../store/slices/organisationSlice";
import { useStyles } from "../User/UserList";
import DataGridTable from "../dataGridTable/DataGridTable";

const OrganisationList = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pageSizeRef = useRef(10);
  const { permissions } = useSelector(userSelector); // _id
  const organisations = useSelector(organisationsSelector);
  const { isFetchingOrgList, page, statusFilter, filterDate } = organisations
  const [searchTerm, setSearchTerm] = useState("");
  const [rows, setRows] = useState([]);
  // const [page, setPage] = useState(0);
  const [rowCountState, setRowCountState] = useState(organisations?.totalDocs || 0);
  const [params, setParams] = useState({ offset: 1, limit: 10 });

  const columns = [
    {
      field: "startDate",
      headerName: "Date",
      type: "string",
      width: 150,
      editable: false,
      filterable: false,
      renderCell: (params) => (
        <div>
          <Typography style={{ fontSize: "14px" }}>{params.value.date}</Typography>
          <Typography style={{ fontSize: "11px", color: "#999" }}>{params.value.time}</Typography>
        </div>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 270,
      // editable: true,
    },
    {
      field: "acn",
      headerName: "ACN",
      width: 150,
      editable: false,
      sortable: false,
    },
    {
      field: "abn",
      headerName: "ABN",
      width: 150,
      editable: false,
      sortable: false,
    },
    {
      field: "acl",
      headerName: "ACL",
      width: 150,
      editable: false,
      sortable: false,
    },
    {
      field: "address",
      headerName: "Address",
      width: 350,
      editable: false,
      // filterable: false,
      sortable: false,
    },
    {
      field: "action",
      headerName: "Action",
      width: 180,
      editable: false,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Button onClick={() => navigate(`/organisation/rolepermissions/${params?.id}`)} variant="outlined">
          edit Permission
        </Button>
      ),
    },
    {
      field: "update",
      headerName: "",
      width: 300,
      editable: false,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Button onClick={() => navigate(`/organisation/${params?.id}`)} variant="outlined">
          update organisation detail
        </Button>
      ),
    },
  ];

  // const handlePageChange = (newPage) => {
  //   handle.getAllOrganisation(newPage + 1, 10);
  //   setPage(newPage);
  // };

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      organisations?.totalDocs !== undefined ? organisations?.totalDocs : prevRowCountState,
    );
  }, [organisations?.totalDocs, setRowCountState]);

  const handle = {
    getAllOrganisation: ({ cursor = 1, search, startDate, endDate, filter = "", isClearDateSelection = false }) => {

      const updatedStartDate = startDate ? startDate : moment(filterDate?.startDate).format("YYYY-MM-DD");
      const updatedEndDate = endDate ? endDate : moment(filterDate?.endDate).format("YYYY-MM-DD");

      dispatch(
        getAllOrganisation({
          search,
          cursor,
          limit: pageSizeRef?.current || 10, // params?.limit
          filter,
          columnFilter: params?.columnFilter,
          sort: params?.sort,
          startDate: (isClearDateSelection || !updatedStartDate || updatedStartDate === "Invalid date") ? undefined : updatedStartDate,
          endDate: (isClearDateSelection || !updatedEndDate || updatedEndDate === "Invalid date") ? undefined : updatedEndDate,
        }),
      );
    },
    handleUserSearch: (searchTerm) => {
      (searchTerm.length >= 2 || searchTerm.length <= 0) &&
        handle.getAllOrganisation({ cursor: organisations?.page, search: searchTerm, filter: statusFilter });
    },
  };

  // useEffect(() => {
  //   if (_id) handle.getAllOrganisation(organisations?.page || 1, 10);
  // }, [_id]);

  useEffect(() => {
    if (params?.offset) {
      handle.getAllOrganisation({
        cursor: params?.offset,
        limit: params?.limit,
        search: searchTerm,
        filter: statusFilter
      });
      dispatch(saveValue({ page: params?.offset, sort: params?.sort, limit: params?.limit }));
    }
  }, [params?.offset, params?.sort, params?.limit, params?.columnFilter]);

  useEffect(() => {
    setRows(
      organisations?.docs?.map((doc) => {
        const date = new Date(doc.createdAt);
        return {
          id: doc._id,
          name: doc.name,
          acn: doc.acn,
          abn: doc.abn,
          acl: doc.acl,
          address: doc.address,
          startDate: {
            date: moment(date).format("D-M-YY"),
            time: moment(date).format("h:mm a"),
          },
        };
      }),
    );
  }, [organisations]);

  const debounced = useCallback(debounce(handle.handleUserSearch, 500), []);

  return (
    <Grid
      container
      item
      direction="column"
      spacing={0}
      style={{
        // marginTop: "64px",
        padding: "20px",
      }}
    >
      <Grid
        container
        item
        justifyContent={"space-between"}
        style={{
          paddingBottom: "5px",
          borderBottom: "1px solid rgba(224, 224, 224, 1)",
        }}
      >
        <Grid
          item
          style={{
            // borderBottom: "1px solid rgba(224, 224, 224, 1)",
            display: "flex",
            alignItems: "center",
            columnGap: "40px",
          }}
        >
          <Typography
            variant="h1"
            style={{
              fontSize: "24px",
              fontWeight: 800,
              letterSpacing: "-0.5px",
            }}
          >
            Organisations
          </Typography>
          <Stack>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <Input
                className="input-border-bottom"
                id="user-search"
                autoComplete="search" // false
                variant="filled"
                placeholder="Search…"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  debounced(e.target.value, 1000);
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                endAdornment={
                  searchTerm && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setSearchTerm("");
                          searchTerm !== "" && debounced("", 1000);
                        }}
                        color="secondary"
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
            </FormControl>
          </Stack>
        </Grid>
      </Grid>

      <Grid item>
        <DataGridTable
          onCellClick={(params, event) => {
            event.defaultMuiPrevented = true;
          }}
          onRowClick={() => { }}
          data={rows || []}
          columns={columns}
          disableSelectionOnClick
          rowsPerPageOptions={[10, 20, 50, 100]}
          pageSize={pageSizeRef?.current || 10}
          rowCount={rowCountState}
          params={params}
          setParams={setParams}
          page={page}
          isLoading={isFetchingOrgList}
          pageSizeRef={pageSizeRef}
          columnVisibilityModel={{ action: !!permissions?.org?.permission_edit, update: !!permissions?.org?.edit }}
        // style={{ background: "#ffffff", border: "none" }}
        />
      </Grid>
    </Grid>
  );
};

export default OrganisationList;
