import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "@reduxjs/toolkit";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { Box, Card, CardContent, Collapse, CardActions, Button, Stack, Grid, Chip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { LoadingButton } from "@mui/lab";
import { lowerCase, merge, round, upperFirst } from "lodash";
// import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";
import calculateEffectiveRate from "../../utils/calculateEffectiveRate";
import getMonthlyRate from "../../utils/getMonthlyRate";
import calculateRepayments from "../../utils/calculateRepayments";
import calculateComparisonRate from "../../utils/calculateComparisonRate";
import {
  userDetailsSelector,
  getApplicationDownloadUrl,
  submitApplication,
  loanDetailsSelector,
  updateLoanDetails,
  saveValue,
  getCurrentProductLenderId,
} from "../../store/slices/applicationFormSlice";
// import { socket } from "src/socket";
import {
  APPLICATION_TYPES,
  ERRORS_MESSAGE,
  SUCCESS_MESSAGE,
  dollarStringOptions,
  lenderListWith2FA,
} from "../../constants";
import ConnectiveSendDisclosure from "../customComponents/ConnectiveSendDisclosure";
import BmaSendDisclosure from "../customComponents/BmaSendDisclosure";
import VerifyDialog from "../customComponents/VerifyDialog";
import { rateIsValid, updateLenderName } from "../Utils/helperFunction";
import { userPermissionsSelector } from "../../store/slices/userSlice";
import { getStringTitleCase } from "../Utils/helperFunction";


const headerBackgroundColors = {
  0: "#e0f2f1",
  1: "#f1f8e9",
  2: "#fffde7",
  3: "#fff3e0",
};

const chipColor = {
  Standard: "#4527a0",
  "Low doc": "#ba68c8",
  "Light doc": "#64b5f6",
  "Full doc": "#607d8b",
  Adverse: "#3f51b5",
};

export const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  (props) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#ffffff",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 400,
      fontSize: props.theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
      padding: props.tooltipPadding,
    },
  }),
);

const Product = React.memo(({ productDetails, application, user, screenSize, onShowRequired }) => {
  const dispatch = useDispatch();

  const emailObject = productDetails?.lenderCredentials?.find((obj) => obj.attribute === "email");

  const { availableDataLength, totalLength, remainingDataFields } = productDetails?.requiredFieldsData;

  const remainingDataFieldsEntity = remainingDataFields?.entity?.flat();
  const remainingDataFieldsCustomer = remainingDataFields?.customer; //?.flat();
  const platformName = process.env.REACT_APP_PLATFORM_NAME;

  const [expanded, setExpanded] = useState(false);
  const [openDisclosureConnectiveDialog, setOpenDisclosureConnectiveDialog] = useState(false);
  const [openDisclosureBmaDialog, setOpenDisclosureBmaDialog] = useState(false);
  const [disableApplicationBtn, setDisableApplicationBtn] = useState(false);
  const { open, url, downloadApplicationLoader, isQuoteOpen } = useSelector(userDetailsSelector);
  const [verifiedDialogBox, setVerifiedDialogBox] = useState({ isOpen: false });
  const { currentProductLenderId } = useSelector(userDetailsSelector);
  const permissions = useSelector(userPermissionsSelector);
  // const fit = productDetails?.fit;
  const qualification = productDetails?.qualification;
  const loanDetails = useSelector(loanDetailsSelector);
  const { enqueueSnackbar } = useSnackbar();
  const isCommercial = application.applicationType === "commercial";
  const asset = application?.asset;

  const getFeeValue = ({ fees, name }) => fees?.find((fee) => fee.name === name)?.value;

  const [disclosureFields, setDisclosureFields] = useState({
    rate: productDetails.rate,
    repayments: productDetails.repayments,
    financeAmount: round(productDetails?.financeAmount, 2),
    originationFee: getFeeValue({ fees: productDetails.fees, name: "Origination fee" }) || 0,
    inspectionFee: getFeeValue({ fees: productDetails.fees, name: "Inspection fee" }) || 0,
    applicationFee:
      getFeeValue({ fees: productDetails.fees, name: "Document fee" }) ||
      getFeeValue({ fees: productDetails.fees, name: "Private sale fee" }) ||
      0,
    effectiveRate: round(productDetails?.effectiveRate, 2) || 0,
    registrationFee: getFeeValue({ fees: productDetails.fees, name: "Registration fee" }) || 0,
    establishmentFee: getFeeValue({ fees: productDetails.fees, name: "Establishment fee" }) || 0,
    accountFee: productDetails.fees?.find((fee) => fee.frequency === "monthly")?.value || 0,
    referralFee: getFeeValue({ fees: productDetails.fees, name: "Referral fee" }) || 0,
    inArrears: isCommercial ? "No" : "Yes",
  });

  const emailApplicationConfigured = user?.lenderSettings?.find((setting) => setting?.LenderEmail);

  const uploadApplicationConfigured = user?.lenderSettings?.find(
    (setting) => setting?.brokerId || setting?.organisationId || setting?.username || setting?.password,
  );

  const isConfiguredForSubmit = user?.lenderSettings?.find((setting) => setting?.lender === productDetails?.lender);

  const handleOpenDisclosureDialog = () => {
    switch (platformName) {
      case "connective":
        setOpenDisclosureConnectiveDialog(true);
        return;
      case "bma":
        setOpenDisclosureBmaDialog(true);
        return;

      default:
        console.log(`please check platform name ${platformName}`);
        return;
    }
  };

  const handleCloseDisclosureDialog = () => {
    switch (platformName) {
      case "connective":
        setOpenDisclosureConnectiveDialog(false);
        return;
      case "bma":
        setOpenDisclosureBmaDialog(false);
        return;
      default:
        console.log(`please check platform name ${platformName}`);
        return;
    }
  };

  const handle = {
    showFields: () => {
      console.log("show field");
      onShowRequired(remainingDataFields);
    },
    onApplicationSubmitForCode: async ({ applicationId, lender, submitType, productDetails }) => {
      handle.onApplicationSubmit({ applicationId, lender, submitType, productDetails });
      setVerifiedDialogBox({ isOpen: true, applicationId: applicationId, lenderName: updateLenderName(lender) });

      // dispatch(verifiedDialogBox(true));
      // socket.emit("join", {
      //   uuidv4: Math.floor(100000 + Math.random() * 900000),
      // });
    },
    onApplicationSubmit: async ({ applicationId, lender, submitType, productDetails }) => {
      await handle.saveQuote();

      dispatch(getCurrentProductLenderId(""));
      dispatch(saveValue({ applyApplication: null }));
      dispatch(getCurrentProductLenderId(lender));
      setDisableApplicationBtn(true);
      dispatch(
        submitApplication({
          submitType: submitType,
          applicationId: applicationId,
          lenderType: updateLenderName(lender),
          productDetails,
        })
      ).then((res) => {
        dispatch(saveValue({ applyApplication: true }));
        setDisableApplicationBtn(false);
        dispatch(getCurrentProductLenderId(""));

        if (res.payload?.data?.data) {
          enqueueSnackbar(res.payload?.status_message || SUCCESS_MESSAGE.linkMsg, {
            variant: "success",
            autoHideDuration: 5000,
          });
        } else {
          // Array.isArray(payload?.status_message) ? ERRORS_MESSAGE.fetchErrorMsg : payload?.status_message,
          enqueueSnackbar(res.payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      });
    },
    onApplicationDownland: async ({ applicationId, lender }) => {
      dispatch(getCurrentProductLenderId(""));
      dispatch(getCurrentProductLenderId(lender));
      dispatch(
        getApplicationDownloadUrl({
          applicationId: applicationId,
        }),
      ).then((res) => {
        if (res?.payload?.data?.data) {
          enqueueSnackbar(SUCCESS_MESSAGE.generatePdf, {
            variant: "success",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(ERRORS_MESSAGE.fetchErrorMsg, {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      });
    },
    expandClickFn: () => {
      setExpanded(!expanded);
      if (user.status === "new" && !expanded) {
        dispatch(saveValue({ walkthroughIndex: 4 }));
      }
    },
    criteriaColorFn: (result) => {
      if (result) return "#2e7d32";
      if (result === undefined) return "#999999";
      return "#c62828";
    },
    extrasStyleFn: (result) => {
      if (result === false) return { color: "inherit", textDecoration: "line-through" };
      if (result === undefined) return { color: "#999999" };
      return { color: "inherit" };
    },
    setHeaderBackground: () => headerBackgroundColors[qualification] || "#fbe9e7",
    criteriaIconsFn: (criteria) => {
      if (criteria.result === true)
        return (
          <CheckCircleIcon
            style={{
              fontSize: "14px",
              color: handle.criteriaColorFn(criteria.result),
            }}
          />
        );
      if (criteria.result === false)
        return (
          <CancelIcon
            style={{
              fontSize: "14px",
              color: handle.criteriaColorFn(criteria.result),
            }}
          />
        );
      return (
        <RemoveCircleIcon
          style={{
            fontSize: "14px",
            color: handle.criteriaColorFn(criteria.result),
          }}
        />
      );
    },
    saveQuote: async () => {
      if (loanDetails?._id)
        await dispatch(
          updateLoanDetails(
            merge({
              ...loanDetails,
              ...{
                loanAmount: round(productDetails?.loanAmount, 2),
                rate: productDetails?.rate,
                repayments: round(productDetails?.repayments, 2),
                brokerage: productDetails?.brokerage || 0,
                brokerageAmount: round(productDetails?.brokerageAmount, 2) || 0,
                // fees: handleFees(),
              },
            }),
          ),
        ).unwrap();
    },
    calculateQuote: (loanAmount, balloon, term, rate, netAssetValue) => {
      const monthlyRate = getMonthlyRate(rate);
      const repayments = calculateRepayments(
        monthlyRate,
        term,
        -loanAmount,
        // (balloon / 100) * asset?.assetValue,
        (balloon / 100) * netAssetValue,
        isCommercial ? 1 : 0,
      );

      return {
        repayments: Number(repayments.toFixed(2)),
      };
    },
    calculateQuoteDisclosure: (loanAmount, balloon, term, rate, netAssetValue, inArrearsValue) => {
      const monthlyRate = getMonthlyRate(rate);
      const repayments = calculateRepayments(
        monthlyRate,
        term,
        -loanAmount,
        // (balloon / 100) * asset?.assetValue,
        (balloon / 100) * netAssetValue,
        inArrearsValue,
      );

      return {
        repayments: Number(repayments.toFixed(2)),
      };
    },
    loanAmountCommercial: (fees, netAssetValue, brokerageAmount) => netAssetValue + brokerageAmount + fees,
    loanAmountConsumer: (fees, netAssetValue) => netAssetValue + fees,
    updateDisclosureRate: ({ rate, inArrears }) => {

      if (rate) {
        const isValid = rateIsValid(rate);

        if (!isValid) {
          return;
        }
      }

      const { term = 0, balloon = 0, deposit = 0, brokerage = 0, tradeIn = 0, payout = 0 } = loanDetails;
      const netAssetValue = asset?.assetValue * 1 - deposit * 1 - tradeIn * 1 + payout * 1;
      const brokerageAmount = netAssetValue * (brokerage / 100);

      // const RATE = (+rate) + rateAdjustment
      // const RATE = (+rate - +productDetails.loading?.loadingTotal) * 1;
      const RATE = +rate;

      let loanAmount = 0

      switch (application?.applicationType) {
        case APPLICATION_TYPES.COMMERCIAL:
          loanAmount = handle.loanAmountCommercial(productDetails?.financeFeesTotal, netAssetValue, brokerageAmount)
          break;

        case APPLICATION_TYPES.CONSUMER:
          loanAmount = handle.loanAmountConsumer(productDetails?.financeFeesTotal, netAssetValue);
          break;

        case APPLICATION_TYPES.PERSONAL:
          // loanAmount = productDetails?.financeFeesTotal + (+loanDetails?.loanAmount)
          loanAmount = productDetails?.financeAmount
          break;

        default:
          break;
      }
      // const inArrearsValue = disclosureFields?.inArrears === "Yes" ? 0 : 1
      const isInArrears = inArrears ? inArrears : disclosureFields?.inArrears
      const inArrearsValue = isInArrears === "Yes" ? 0 : 1

      const quoteDetails = handle.calculateQuoteDisclosure(loanAmount, balloon, term, RATE, netAssetValue, inArrearsValue);
      const quoteWithNoBalloon = handle.calculateQuoteDisclosure(loanAmount, 0, term, RATE, netAssetValue, inArrearsValue);

      // const balloonAmount = ((balloon / 100) * asset?.assetValue)
      const balloonAmount = ((balloon / 100) * netAssetValue)
      const presentValue = balloonAmount / Math.pow(1 + RATE, term)
      const trueLoanAmount = loanAmount - presentValue

      // const comparisonRateRaw = calculateComparisonRate(
      //   term,
      //   -quoteWithNoBalloon.repayments,
      //   trueLoanAmount - productDetails?.feesTotal,
      //   0, // (balloon / 100) * assetValue
      //   0,
      //   RATE / 100,
      // );

      const comparisonRateRaw = calculateComparisonRate(
        term,
        -quoteWithNoBalloon.repayments,
        // trueLoanAmount - productDetails?.feesTotal - (isCommercial ? brokerageAmount : 0),
        trueLoanAmount - productDetails?.financeFeesTotal - (isCommercial ? brokerageAmount : 0),
        balloon, // (balloon / 100) * assetValue
        // isCommercial ? 1 : 0,
        inArrearsValue,
        RATE / 100,
      );

      // const effectiveRateRaw = calculateComparisonRate(
      //   term,
      //   -quoteWithNoBalloon.repayments,
      //   netAssetValue + productDetails?.feesTotal,
      //   balloon,
      //   isCommercial ? 1 : 0,
      //   RATE / 100,
      // );

      // // const effectiveRate = (effectiveRateRaw * 12 * 100) + (!isCommercial ? 0.01 : 0);
      // let effectiveRate = (effectiveRateRaw * 12 * 100)

      // if (effectiveRate < RATE) {
      //   effectiveRate = RATE
      // }

      const effectiveRate = calculateEffectiveRate(
        term,
        quoteDetails.repayments,
        // -(netAssetValue + productDetails?.feesTotal),
        -(netAssetValue + productDetails?.financeFeesTotal),
        balloonAmount,
        // isCommercial ? 1 : 0,
        inArrearsValue,
        RATE / 100
      );

      // const comparisonRate = comparisonRateRaw * 12 * 100;
      let comparisonRate = comparisonRateRaw * 12 * 100;

      if (comparisonRate < RATE) {
        comparisonRate = RATE
      }

      // const compoundingYears = 12
      // const newRate = rate / 100
      // const compoundRate = newRate / compoundingYears
      // const effectiveRate = (Math.pow(1 + compoundRate, compoundingYears) - 1) * 100

      setDisclosureFields({
        ...disclosureFields,
        effectiveRate: effectiveRate,
        comparisonRate: comparisonRate,
        repayments: quoteDetails.repayments + (productDetails?.monthlyFees?.value || 0),
        rate: rate,
        inArrears: isInArrears,
      });
    },
  };

  // const titleCaseFun = (value) => {
  //   let val = startCase(value?.split(regex.startCaseRegex)[1])
  //     ? startCase(value?.split(regex.startCaseRegex)[1])
  //     : value;

  //   return val;
  // };

  function calculateGridSize(productDetails, screenSize) {
    const loadingListFirstItem = productDetails?.loading?.loadingList?.[0];
    const feesFirstItem = productDetails?.fees?.[0];
    const isDynamicWidthLessThan900 = screenSize.dynamicWidth < 900;

    if (loadingListFirstItem || feesFirstItem) {
      return isDynamicWidthLessThan900 ? 12 : 4;
    } else {
      return isDynamicWidthLessThan900 ? 12 : 7;
    }
  }

  // const requiredFieldLabell = (fieldLabel) => {
  //   if (fieldLabel === "emails?.slice(-1)[0].address") {
  //     return "email";
  //   } else if (fieldLabel.startsWith("addresses[0]")) {
  //     return upperFirst(lowerCase(titleCaseFun(fieldLabel)));
  //   } else if (fieldLabel.startsWith("add")) {
  //     return fieldLabel.replace(/addresses\[(.*?)\]\./, "previous ");
  //   } else {
  //     return upperFirst(lowerCase(titleCaseFun(fieldLabel)));
  //   }
  // };

  const requiredFieldLabel = (fieldLabel) => {
    const isNumeric = (string) => /^[+-]?\d+(\.\d+)?$/.test(string);
    const isAlpha = (string) => /^[a-zA-Z]+$/.test(string);
    const map1 = fieldLabel.map((x) => {
      let temp = "";
      if (x.includes(".")) {
        const value = x.split(".")[1]
        if (value === "[0]") {
          temp = x.split(".")[2]
        } else {
          temp = value
        }
      } else {
        temp = x;
      }
      if (temp.includes("_")) {
        let undrscrArray = temp.split("_");
        if (undrscrArray.length == 2) temp = undrscrArray[0];
        if (undrscrArray.length > 2) {
          let undrscrArray1 = undrscrArray.map((val) => {
            let resVal = "";
            if (isNumeric(val) || !isAlpha(val) || val.includes("customError")) {
              // if (isNumeric(val) || !isAlpha(val)) {
              // console.log("isNaN",val)
              resVal = resVal;
            } else {
              if(val === "timeOfEmployment"){
                resVal = `${val} > 24`;
              }else{
                resVal = val;
              }
              // console.log(val)
            }
            return resVal;
          });
          undrscrArray1 = undrscrArray1.filter((x) => x !== "");
          // console.log("undrscrArray", undrscrArray1)
          undrscrArray1 = undrscrArray1.reverse();
          temp = undrscrArray1.join(" ");
        }
      }
      return temp;
    });
    // console.log(map1);
    const map2 = [];
    map1.forEach((x) => {
      if (!map2.includes(x)) map2.push(x);
      return x;
    });
    return map2;
  };

  // console.log("remainingDataFields", remainingDataFields);

  return (
    <React.Fragment>
      {open ? (
        <Card
          className="tenth-step"
          elevation={1}
          style={{
            margin: "0 0 12px",
            background: handle.setHeaderBackground(),
            borderRadius: "8px",
          }}
        >
          <CardContent
            style={{
              padding: "0 20px",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              style={{ padding: "7px 5px 5px 0" }}
            >
              <Typography
                variant="body2"
                style={{
                  marginRight: "20px",

                  fontWeight: 600,
                  textAlign: "left",
                }}
              >
                <span>Criteria: {productDetails?.criteria}</span>
              </Typography>

              <Stack direction="row" spacing={1}>
                {/* {completedLender.includes(productDetails?.lender) && <Chip label="Live" size="small" color="success" />} */}
                {productDetails?.labels?.map((label) => (
                  <Chip
                    key={nanoid()}
                    size="small"
                    label={label}
                    style={{
                      padding: "0 2px",
                      height: "22px",
                      color: "#fff",
                      background: chipColor[label],
                    }}
                  />
                ))}
              </Stack>
            </Stack>

            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Grid item container justifyContent="space-between" alignItems="center">
                <Grid item xs={screenSize.dynamicWidth < 900 ? 5 : 8} alignItems="center">
                  <Stack
                    direction={screenSize.dynamicWidth < 900 ? "column" : "row"}
                    justifyContent="center"
                    alignItems={screenSize.dynamicWidth < 900 ? "start" : "center"}
                  >
                    <Grid
                      item
                      xs={screenSize.dynamicWidth < 900 ? 6 : 4}
                      style={{}}
                      alignItems="center"
                    >
                      {productDetails?.logo
                        ? <img
                          src={productDetails?.logo}
                          alt={getStringTitleCase(productDetails?.lender)}
                          style={{
                            width:
                              screenSize.dynamicWidth < 900 ? "100%" : "60%",
                            height: "auto",
                            margin: "auto",
                            display: "flex",
                          }} />
                        : <Typography
                          variant="body2"
                          style={{
                            width:
                              screenSize.dynamicWidth < 900 ? "100%" : "60%",
                            height: "auto",
                            margin: "auto",
                            display: "flex",
                          }}
                        >
                          {getStringTitleCase(productDetails?.lender)}
                        </Typography>
                      }

                    </Grid>
                    <Grid item xs={screenSize.dynamicWidth < 900 ? 12 : 8}>
                      <Stack direction="column">
                        {screenSize.dynamicWidth > 900 && (
                          <>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="subtitle"
                              style={{ fontSize: "14px", fontWeight: "400" }}
                            >
                              {productDetails?.productName}
                            </Typography>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="subtitle"
                              style={{
                                marginRight: "5px",
                                fontSize: "16px",
                                fontWeight: "700",
                              }}
                            >
                              {productDetails?.lender}
                            </Typography>
                          </>
                        )}
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="h5"
                          style={{
                            marginRight: "5px",
                            marginBottom: "-5px",
                            fontWeight: "700",
                          }}
                        >
                          {productDetails?.repayments?.toLocaleString("en-US", dollarStringOptions)}
                          /mth
                        </Typography>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="caption"
                          style={{ marginRight: "5px", fontSize: "11px" }}
                        >
                          {productDetails?.monthlyFees &&
                            `incl. ${productDetails?.monthlyFees?.value?.toLocaleString(
                              "en-US",
                              dollarStringOptions,
                            )} monthly fees`}
                        </Typography>
                        {screenSize.dynamicWidth > 900 && (
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body1"
                            style={{ marginRight: "5px" }}
                          >
                            {(productDetails?.repayments / 4.33)?.toLocaleString("en-US", dollarStringOptions)}
                            /wk
                          </Typography>
                        )}
                      </Stack>
                    </Grid>
                  </Stack>
                </Grid>
                <Grid item xs={screenSize.dynamicWidth < 900 ? 7 : 4} alignItems="center">
                  <Typography
                    sx={{ display: "inline" }}
                    variant="body2"
                    style={{
                      marginRight: "5px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    Lender rate: <span>{(1 * productDetails?.rate).toFixed(2)}%</span>
                  </Typography>

                  <Typography
                    sx={{ display: "inline" }}
                    variant="body2"
                    style={{
                      marginRight: "5px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    Effective rate: <span>{(1 * productDetails?.effectiveRate).toFixed(2)}%</span>
                  </Typography>

                  <Typography
                    sx={{ display: "inline" }}
                    variant="body2"
                    style={{
                      marginRight: "5px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    Comparison rate: <span>{(1 * productDetails?.comparisonRate).toFixed(2)}%</span>
                  </Typography>

                  <Typography
                    sx={{ display: "inline" }}
                    // component="span"
                    variant="body2"
                    style={{
                      marginRight: "5px",

                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    Finance amount:
                    <span>{productDetails?.financeAmount?.toLocaleString("en-US", dollarStringOptions)}</span>
                  </Typography>

                  {application?.applicationType === APPLICATION_TYPES.COMMERCIAL && (
                    <Typography
                      sx={{ display: "inline" }}
                      variant="body2"
                      style={{
                        marginRight: "5px",

                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Brokerage
                      <span>
                        {productDetails?.brokerage?.toFixed(2)}% /{" "}
                        {productDetails?.brokerageAmount?.toLocaleString("en-US", dollarStringOptions)}
                      </span>
                    </Typography>
                  )}

                  <Typography
                    sx={{ display: "inline" }}
                    variant="body2"
                    color={productDetails?.score ? "#222222" : "aaaaaa"}
                    style={{
                      marginRight: "5px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Stack
                      direction="column"
                      style={{
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Stack direction="row" justifyContent="space-between">
                        <span>Total upfront fees:</span>
                        <span>{productDetails?.feesTotal?.toLocaleString("en-US", dollarStringOptions)}</span>
                      </Stack>
                    </Stack>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
          <CardActions
            disableSpacing
            style={{
              justifyContent: "space-between",
              padding: "10px 20px",
            }}
          >
            <Button
              className="fifth-step"
              size="small"
              expand={expanded}
              onClick={handle.expandClickFn}
              aria-expanded={expanded}
              aria-label="show more"
              color="secondary"
              style={{
                padding: "0 10px",
                minWidth: "auto",
                fontWeight: "bold",
              }}
            >
              {expanded ? (
                <React.Fragment>
                  Hide <ExpandLessIcon />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  Show criteria <ExpandMoreIcon />
                </React.Fragment>
              )}
            </Button>

            <Stack spacing={1} direction="row" justifyContent="center">
              {!isQuoteOpen && remainingDataFields && (
                <HtmlTooltip
                  // disableHoverListener={isConfiguredForSubmit}
                  tooltipPadding={"20px 20px 5px 20px"}
                  title={
                    <React.Fragment>
                      <Typography
                        color="inherit"
                        style={{
                          marginBottom: "20px",
                          fontWeight: "600",
                          borderBottom: "1px solid #ddd",
                          paddingBottom: "15px",
                        }}
                      >
                        Required fields remaining
                      </Typography>
                      <Stack
                        sx={{
                          overflowY: "auto",
                          overflowX: "hidden",
                          height: "280px",
                          scrollbarColor: "white rebeccapurple",
                          scrollbarWidth: "thin",
                          "&::-webkit-scrollbar": {
                            width: "7px",
                            height: "7px",
                          },
                          "&::-webkit-scrollbar-track": {
                            background: "#f1f1f1",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#999",
                          },
                          "&::-webkit-scrollbar-thumb:hover": {
                            background: "#616161",
                          },
                        }}
                      >
                        {/* <div style={{ maxHeight: "280px", overflow: "auto" }}> */}
                        {remainingDataFields?.asset?.length > 0 && (
                          <Typography color="inherit" marginBottom="5px" variant="body2" sx={{ fontWeight: 600 }}>
                            Assets:
                          </Typography>
                        )}
                        {remainingDataFields?.asset?.map((field) => (
                          <React.Fragment>
                            <Typography
                              color="inherit"
                              fontSize="14px"
                              marginBottom="10px"
                              sx={{
                                "&:hover": {
                                  color: "#d32f2f",
                                },
                              }}
                            >
                              {upperFirst(lowerCase(field))}

                              {/* {startCase(field?.split(regex.startCaseRegex)[1])} */}
                            </Typography>
                          </React.Fragment>
                        ))}
                        {application?.customers?.length > 0 && (
                          <React.Fragment>
                            {remainingDataFieldsCustomer?.length > 0 && (
                              <Typography color="inherit" marginBottom="5px" variant="body2" sx={{ fontWeight: 600 }}>
                                {application?.applicationType === APPLICATION_TYPES.COMMERCIAL
                                  ? "Guarantor"
                                  : "Borrower"}
                                :
                              </Typography>
                            )}
                            {requiredFieldLabel(remainingDataFieldsCustomer)?.map((field, index) => (
                              <React.Fragment>
                                <Typography
                                  color="inherit"
                                  fontSize="14px"
                                  marginBottom="10px"
                                  sx={{
                                    "&:hover": {
                                      color: "#d32f2f",
                                    },
                                  }}
                                >
                                  {/* {Object.keys(field).map((f)=>requiredFieldLabel(f))} */}
                                  {field}
                                </Typography>
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        )}
                        {application?.entities?.length > 0 && (
                          <React.Fragment>
                            {remainingDataFieldsEntity?.length > 0 && (
                              <Typography color="inherit" marginBottom="5px" variant="body2" sx={{ fontWeight: 600 }}>
                                Entity:
                              </Typography>
                            )}
                            {requiredFieldLabel(remainingDataFieldsEntity).map((field) => (
                              <React.Fragment>
                                <Typography
                                  color="inherit"
                                  fontSize="14px"
                                  marginBottom="10px"
                                  sx={{
                                    "&:hover": {
                                      color: "#d32f2f",
                                    },
                                  }}
                                >
                                  {/* {upperFirst(lowerCase(titleCaseFun(field)))} */}
                                  {field}
                                </Typography>
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        )}
                        {remainingDataFields?.agreement?.length > 0 && (
                          <Typography color="inherit" marginBottom="5px" variant="body2" sx={{ fontWeight: 600 }}>
                            Agreement:
                          </Typography>
                        )}
                        {remainingDataFields?.agreement?.map((field) => (
                          <React.Fragment>
                            <Typography
                              color="inherit"
                              fontSize="14px"
                              marginBottom="10px"
                              sx={{
                                "&:hover": {
                                  color: "#d32f2f",
                                },
                              }}
                            >
                              {upperFirst(lowerCase(field))}
                            </Typography>
                          </React.Fragment>
                        ))}
                      </Stack>
                      <Stack
                        sx={{
                          marginTop: "20px",
                          fontWeight: "600",
                          borderTop: "1px solid #ddd",
                          paddingTop: "5px",
                          width: "100%",
                        }}
                      >
                        <Button
                            onClick={handle.showFields}
                            disabled={!permissions?.application?.edit}
                          >
                          Show Fields</Button>
                      </Stack>
                    </React.Fragment>
                  }
                >
                  <Stack direction="row" spacing={0.5} sx={{ margin: "0 10px" }}>
                    <Typography variant="subtitle1" fontWeight={600}>
                      Required fields:
                    </Typography>

                    <Typography variant="subtitle1" fontWeight={600}>
                      {/* data available / Required field length */}
                      {availableDataLength || 0} / {totalLength || 0}
                    </Typography>
                  </Stack>
                </HtmlTooltip>
              )}

              {!isQuoteOpen && ["connective", "bma"].includes(platformName) && (
                <span>
                  <LoadingButton
                    color="secondary"
                    endIcon={<SendOutlinedIcon />}
                    loadingPosition="end"
                    variant="outlined"
                    size="small"
                    style={{
                      padding: "0 10px",
                      minWidth: "auto",
                      fontWeight: 600,
                    }}
                    disabled={!permissions?.application?.edit}
                    onClick={async () => {
                      //   handle.saveQuote();
                      //   await dispatch(
                      //     sendDisclosure({
                      //       applicationId: application._id,
                      //       lenderType: productDetails?.lender,

                      //     })
                      //   ).unwrap();
                      handleOpenDisclosureDialog();
                    }}
                  >
                    Send Disclosure
                  </LoadingButton>
                </span>
              )}
              {!isQuoteOpen && (
              <span>
                <LoadingButton
                  color="secondary"
                  endIcon={<SendOutlinedIcon />}
                  loadingPosition="end"
                  variant="outlined"
                  size="small"
                  style={{
                    padding: "0 10px",
                    minWidth: "auto",
                    fontWeight: 600,
                  }}
                  disabled={
                    !permissions?.application?.edit ||
                    (currentProductLenderId === productDetails?.lender &&
                      downloadApplicationLoader) ||
                    availableDataLength !== totalLength
                  }
                  onClick={async () => {
                    handle.onApplicationDownland({
                      applicationId: application._id,
                      lender: productDetails?.lender,
                    });
                  }}
                  href={url}
                >
                  {downloadApplicationLoader && currentProductLenderId === productDetails?.lender
                    ? "Downloading..."
                    : "Download application"}
                </LoadingButton>
              </span>
              )}
              {!isQuoteOpen && productDetails?.actions?.includes("email") && (
                <HtmlTooltip
                  tooltipPadding={"20px"}
                  disableHoverListener={isConfiguredForSubmit && emailApplicationConfigured}
                  title={
                    !emailObject && (
                      <React.Fragment>
                        <Typography color="inherit" marginBottom="5px">
                          Looks like you're not setup with {productDetails.lender}.
                        </Typography>
                        <Typography color="inherit" fontSize="14px" marginBottom="10px">
                          Click on the link below to add your credentials.
                        </Typography>
                        <Link to="/settings/lenders">
                          <Button variant="outlined" size="small">
                            Add credentials
                          </Button>
                        </Link>
                      </React.Fragment>
                    )
                  }
                >
                  <span>
                    <LoadingButton
                      color="secondary"
                      endIcon={<SendOutlinedIcon />}
                      loadingPosition="end"
                      variant="outlined"
                      size="small"
                      style={{
                        padding: "0 10px",
                        minWidth: "auto",
                        fontWeight: 600,
                      }}
                      disabled={
                        (disableApplicationBtn && currentProductLenderId === productDetails?.lender) ||
                        availableDataLength !== totalLength
                      }
                      onClick={() =>
                        handle.onApplicationSubmit({
                          submitType: "email",
                          applicationId: application._id,
                          lender: productDetails?.lender,
                          productDetails: {
                            productName: productDetails.productName,
                            productType: productDetails.productType,
                          },
                        })
                      }
                    >
                      {disableApplicationBtn && currentProductLenderId === productDetails?.lender
                        ? "Sending"
                        : "Email application"}
                    </LoadingButton>
                  </span>
                </HtmlTooltip>
              )}
              {!isQuoteOpen && productDetails?.actions?.includes("upload") && (
                <HtmlTooltip
                  tooltipPadding={"20px"}
                  disableHoverListener={isConfiguredForSubmit && uploadApplicationConfigured}
                  title={
                    !emailObject && (
                      <React.Fragment>
                        <Typography color="inherit" marginBottom="5px">
                          Looks like you're not setup with
                          {productDetails.lender}.
                        </Typography>
                        <Typography color="inherit" fontSize="14px" marginBottom="10px">
                          Click on the link below to add your credentials.
                        </Typography>
                        <Link to="/settings/lenders">
                          <Button variant="outlined" size="small">
                            Add credentials
                          </Button>
                        </Link>
                      </React.Fragment>
                    )
                  }
                >
                  <span>
                    <LoadingButton
                      color="secondary"
                      endIcon={<SendOutlinedIcon />}
                      loadingPosition="end"
                      variant="outlined"
                      size="small"
                      // disabled={
                      //   availableDataLength === totalLength ? false : true
                      // }
                      style={{
                        padding: "0 10px",
                        minWidth: "auto",
                        fontWeight: 600,
                      }}
                      disabled={
                        (disableApplicationBtn && currentProductLenderId === productDetails?.lender) ||
                        availableDataLength !== totalLength
                      }
                      onClick={() => {
                        const lenderDetail = {
                          submitType: "upload",
                          applicationId: application._id,
                          lender: productDetails?.lender,
                          productDetails: {
                            productName: productDetails.productName,
                            productType: productDetails.productType,
                          },
                        };
                        lenderListWith2FA.includes(productDetails?.lender)
                          ? handle.onApplicationSubmitForCode(lenderDetail)
                          : handle.onApplicationSubmit(lenderDetail);
                      }}
                    >
                      {disableApplicationBtn && currentProductLenderId === productDetails?.lender
                        ? "Uploading"
                        : "Upload application"}
                    </LoadingButton>
                  </span>
                </HtmlTooltip>
              )}
            </Stack>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent style={{ padding: "5px 20px 10px" }}>
              <Grid item container spacing={3}>
                <Grid item className="sixth-step" xs={screenSize.dynamicWidth < 900 ? 7 : 4}>
                  <Typography variant="subtitle">
                    <strong>Criteria</strong>
                  </Typography>
                  {productDetails?.criteriaResults
                    ?.sort((x, y) => {
                      if (x.result && x.result !== y.result) return -1;
                      if (x.result === undefined && y.result) return 1;
                      if (y.result === undefined && !x.result) return 1;
                      if (x.result === y.result) return 0;
                      if (!y.result) return -1;
                      return 0;
                    }) // sort from true to false
                    ?.map((criteria) => (
                      <Stack direction="row" alignItems="center">
                        {handle.criteriaIconsFn(criteria)}
                        <Typography variant="body2" style={{ marginLeft: "5px" }}>
                          {criteria.label}
                        </Typography>
                      </Stack>
                    ))}
                </Grid>
                {(productDetails?.loading?.loadingList?.[0] || productDetails?.fees?.[0]) && (
                  <Grid item xs={screenSize.dynamicWidth < 900 ? 5 : 4}>
                    <Typography variant="subtitle">
                      <strong>Extras</strong>
                    </Typography>

                    <React.Fragment>
                      {productDetails?.loading?.loadingList
                        ?.sort((x, y) => {
                          if (x.result && x.result !== y.result) return -1;
                          if (x.result === undefined && y.result) return 1;
                          if (y.result === undefined && !x.result) return 1;
                          if (x.result === y.result) return 0;
                          if (!y.result) return -1;
                          return 0;
                        })
                        ?.map((load) => (
                          <Stack
                            // key={fee?.name}
                            direction="row"
                            justifyContent="space-between"
                            style={handle.extrasStyleFn(load.result)}
                          >
                            <Typography
                              // key={load?.name}
                              variant="body2"
                            // color={handle.criteriaColorFn(load.result)}
                            >
                              {load?.name}:
                            </Typography>
                            <Typography
                              // key={load?.name}
                              variant="body2"
                            // color={handle.criteriaColorFn(load.result)}
                            >
                              {load?.value}%
                            </Typography>
                          </Stack>
                        ))}
                      {productDetails?.fees?.map((fee) => (
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="body2">{fee?.name === "Origination fee" ? "Maximum origination fee" : fee?.name}:</Typography>
                          <Typography variant="body2">
                            {fee?.value?.toLocaleString("en-US", dollarStringOptions)}
                          </Typography>
                        </Stack>
                      ))}
                    </React.Fragment>
                  </Grid>
                )}

                <Grid item xs={calculateGridSize(productDetails, screenSize)}>
                  {productDetails?.info?.[0] && (
                    <>
                      <Typography variant="subtitle">
                        <strong>Info & Requirements</strong>
                      </Typography>
                      <ul
                        style={{
                          fontSize: "12.5px",
                          listStyle: "inside",
                          textAlign: "justify",
                        }}
                      >
                        {productDetails?.info?.map((info) => (
                          <li>{info}</li>
                        ))}
                      </ul>
                    </>
                  )}
                </Grid>
              </Grid>
              <Box
                style={{
                  margin: "10px 0 0",
                  textAlign: "left",
                }}
              >
                <Typography variant="caption">Lender rates as of: {productDetails?.date}</Typography>
              </Box>
            </CardContent>
          </Collapse>
        </Card>
      ) : (
        <Card
          alignItems="flex-start"
          style={{
            margin: "0 0 10px 0",
            background: handle.setHeaderBackground(),
          }}
        >
          <CardContent style={{ padding: "5px 10px" }}>
            <Stack
              direction="row"
              spacing={2}
              style={{
                alignItems: "center",
                height: "60px",
              }}
            >
              {productDetails?.logo
                ? <img
                  width="60px"
                  src={productDetails?.logo}
                  alt={getStringTitleCase(productDetails?.lender)} />
                : <Typography variant="body2" style={{ fontSize: '14px' }}>{getStringTitleCase(productDetails?.lender)}</Typography>
              }
              <Stack
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="body2"
                  style={{
                    textAlign: "left",
                    fontSize: "12px",
                  }}
                >
                  {productDetails.criteria} criteria
                </Typography>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  style={{ marginRight: "5px", fontSize: "14px" }}
                >
                  <span>${productDetails?.repayments?.toFixed(2)}/mth</span>
                </Typography>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  style={{ marginRight: "5px", fontSize: "14px" }}
                >
                  <span>{Number(productDetails?.rate)?.toFixed(2)}%</span>
                </Typography>
                {/* <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  style={{ marginRight: "5px", fontSize: "14px" }}
                >
                  <span>
                    {productDetails?.brokerage?.toFixed(2)}% / {productDetails?.brokerageAmount?.toLocaleString(
                      "en-US",
                      dollarStringOptions
                    )}
                  </span>
                </Typography> */}
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      )}

      <ConnectiveSendDisclosure
        productDetails={productDetails}
        application={application}
        openDisclosureDialog={openDisclosureConnectiveDialog}
        handleOpenDisclosureDialog={handleOpenDisclosureDialog}
        handleCloseDisclosureDialog={handleCloseDisclosureDialog}
        disclosureFields={disclosureFields}
        setDisclosureFields={setDisclosureFields}
        updateDisclosureRate={handle.updateDisclosureRate}
        loanDetails={loanDetails}
      />
      <BmaSendDisclosure
        productDetails={productDetails}
        application={application}
        disclosureFields={disclosureFields}
        openDisclosureDialog={openDisclosureBmaDialog}
        setDisclosureFields={setDisclosureFields}
        handleOpenDisclosureDialog={handleOpenDisclosureDialog}
        handleCloseDisclosureDialog={handleCloseDisclosureDialog}
        updateDisclosureRate={handle.updateDisclosureRate}
        loanDetails={loanDetails}
      />
      <VerifyDialog
        verifiedDialogBox={verifiedDialogBox}
        setVerifiedDialogBox={setVerifiedDialogBox}
        title={"Lender verification"}
        message={"Lender verify Two-factor authentication code"}
      />
    </React.Fragment>
  );
});

export default Product;
